.products {
    background-color: var(--overlay);
    color: #fff;
    height: 70vh;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 1rem;
    padding: 5rem;
}

.products:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.products header {
    font-size: 3rem;
}












