.home {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.home:before {
    content: '';
    background: url('./images/home.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.home .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.home .content p:first-child {
    font-size: 5rem;
    font-weight: 700;
    text-align: center;
}

.home .content p:nth-child(2) {
    font-size: 2.2rem;
    text-align: center;
}


@media screen and (max-width:940px) {
    .home .content p:first-child {
        font-size: 2.6rem;
    }

    .home .content p:nth-child(2) {
        font-size: 1.6rem;
    }
}










