.ingredients {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 1rem;
    padding: 5rem;
}

.ingredients:before {
    content: '';
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.ingredients header {
    font-size: 3rem;
}

.ingredients .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem;
    overflow-y: auto;
}

@media screen and (max-width:940px) {
    .ingredients {
        background: url('./images/brick.jpg') no-repeat center center/cover;
        overflow-y: auto;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

    }

    .ingredients img {
        padding: 0rem;
        width: auto;
        height: 20%;
    }
    
}