.about {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 18px;
    padding: 8rem;
    overflow-y: auto;
}

.about:before {
    content: '';
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.about header {
    font-size: 3rem;
}

.table-container {
    display: table;
  }
  
  .table-item {
    color: #fff;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
    width: 50%;
    font-size: 18px;
    text-align: center;
  }

@media screen and (max-width:940px) {
    .about {
        width: 100%;
    }

    .darkened-image {
        filter: brightness(50%);
    }
}








